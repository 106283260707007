<template>
  <div class="inner-layout-800">

    <div class="detailP-subject-box">
      <div class="category">{{ item.categoryName }}</div>
      <div class="heading-1">{{ item.title }}</div>
    </div>

    <div class="detailP-profile-lable-box">
      <profile :profileType="'detail'" :item="item"/>
      <div v-if="item.writerIdx == getId" class="edite-dot-box" v-click-outside="closeEditBox">
        <div class="icon-dot" @click="openEditBox"><i></i><i></i><i></i></div>
        <div class="menu-box" v-if="isOpenEditBox" >
          <div @click="$router.push(`/ggomunity/${idx}/modify`)"><span>수정</span><span class="icon-img icon-edite"></span></div>
          <div @click="confirmRemovePopup"><span>삭제</span><span class="icon-img icon-trash"></span></div>
        </div>
      </div>
    </div>

    <div class="detailP-thumb">
      <image-alt :src="item.src" altType="detail"/>
    </div>

    <div v-html="item.content && String(item.content).nl2br()" class="detail-content"/>

    <div v-if="attach.length > 0" class="content-image-box">
      <div v-for="(item, index) in attach" :key="`attach${index}`">
        <img class="editorImage" :src="item.src" :alt="item.realFileName" :title="item.realFileName" />
      </div>
    </div>

    <quick-box :cateCode="80" :item="item" :src="getSrc"/>

    <div class="horizon-bar mb-60"></div>

    <comment ref="comments" :cateCode="80" :count="item.comment"/>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import viewMixin from "@/mixins/viewMixin"
export default {
  mixins: [viewMixin],
  data: function () {
    return {
      idx: this.$route.params.idx,
      item: {},
      attach: [],
      isOpenEditBox : false
    }
  },
  computed: {
    ...mapGetters({
      getId: 'session/getId',
    }),
    getSrc() {
      if (this.attach[0]) return this.attach[0].src
      return null
    },
  },
  created() {
    this.setItem();
    setTimeout(() => {
      const tagDivArr = document.getElementsByClassName('tag-div')
      if (tagDivArr) {
        for (let tagDiv of tagDivArr) {
          // tagDiv.addEventListener('click', (event) => {
          //   event.target.nextElementSibling.style.display =  event.target.nextElementSibling.style.display === 'none' ? 'flex' : 'none'
          // })
          tagDiv.addEventListener('mouseenter', (event) => {
            event.target.querySelector('a.tag-product-box').style.display = 'flex'
          })
          tagDiv.addEventListener('mouseleave', (event) => {
            event.target.querySelector('a.tag-product-box').style.display = 'none'
          })
        }
      }
    }, 1000)
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setItem() {
      if (!this.idx) await this.$router.push('/ggomunity');
      const { result, data, attach } = await this.$api.getBoardDetail({ cateCode: 80, boIdx: this.idx })
      if (result === 'success') {
        this.item = data
        if (attach) this.attach = attach
      }
      else if (result === 'empty') this.$router.push('/ggomunity').then()
      else if (result === 'blind') {
        this.setMsgPopup({
          type: 'alert',
          message: this.$msg('ok.blinded'),
          okay: 'BLIND',
          okayCallback: () => {
            this.setMsgPopup()
            this.$router.go(-1)
          },
        })
      }
      else if (result === 'deleted') {
        this.setMsgPopup({
          type: 'alert',
          message: this.$msg('ok.deleted'),
          okay: '',
          okayCallback: () => {
            this.setMsgPopup()
            this.$router.go(-1)
          },
        })
      }
    },
    async remove() {
      const { result } = await this.$api.removeGgomunity({ boIdx: this.idx })
      if (result === 'success') location.href = '/ggomunity'
    },
    openEditBox() {
      this.isOpenEditBox = true
    },
    closeEditBox() {
      this.isOpenEditBox = false
    },
    confirmRemovePopup() {
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.remove'),
        okay: 'REMOVE',
        okayCallback: () => {
          this.setMsgPopup()
          this.remove()
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
  }
}
</script>
